import styles from './style.module.css';

const Toast = (props)=>{
	const {show, type} = props;
	const {setShowModal} = props;
	let {error} = props;

	const message =  type === 'success' ? 'Your inquiry has been received' : `${error}`;
	const title =  type === 'success' ? 'Success' : 'Failed';

	return(
		<div id={`${styles.toast}`} className={`${type === 'success' ? styles.success : styles.error} ${show ? styles.show : styles.hide}`}>
			<div id={`${styles.component}`}>
				<div id={`${styles.title}`}>
					{title}
				</div>
				<div id={`${styles.message}`}>
					{message}
				</div>
			</div>
			<div id={`${styles.closeBtn}`} onClick={()=>{setShowModal(false)}}>
				X
			</div>
		</div>
	);
}


export default Toast;